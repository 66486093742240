<template>
  <div class="currency-controller">
    <b-form-select
        v-model="selectedOption"
        :options="activeCurrencies"
        @change="handelCurrencyChange()"
    >
      <template #first>
        <b-form-select-option :value="0" disabled>
          {{ $t('choose_currency') }}
        </b-form-select-option>
      </template>
    </b-form-select>
  </div>
</template>
<script>
import {BFormSelect,BFormSelectOption} from "bootstrap-vue";

export default {
  name: "CurrencyControllerComponent",
  components: {
    BFormSelect,
    BFormSelectOption
  },
  data() {
    return {
      options: [],
      selectedOption: null
    };
  },
  created() {
    // this.getActiveCurrencies();
  },
  computed: {
    activeCurrencies() {
      return this.$store.getters.GET_CURRENCIES
    },
    activeCurrenciesId() {
      const id = this.$store.getters.getActiveCurrency
      if (this.activeCurrencies.length > 0){
        this.selectedOption = this.activeCurrencies.filter(({currency_id})=> currency_id === id )[0].value
      }
      return id
    }
  },
  methods: {
    async handelCurrencyChange() {
      await this.submitEdit(this.selectedOption);
      location.reload();
    },

    async submitEdit(newCurrencyId) {
      try {
        const formBody = new FormData();
        formBody.append("id", newCurrencyId);
        await this.$http.post("auth/student/set-currency", formBody);
      } catch (err) {
        this.$helpers.handleError(err);
      }
    },
  },
    watch: {
      'activeCurrenciesId': {
        deep: true,
        handler(newValue) {
          if (this.activeCurrencies.length > 0){
              this.selectedOption = this.activeCurrencies.filter(({currency_id})=> currency_id === newValue )[0].value
          }
        }
      }
    }
};
</script>
