<template>
  <div>
    <b-nav-item-dropdown
      id="dropdown-grouped"
      variant="link"
      class="dropdown-language"
      right
    >
      <template @click="changeLang" #button-content>
        <b-img
          :src="currentLocale.img"
          height="14px"
          width="22px"
          :alt="currentLocale.locale"
        />
        <span class="ml-50 text-body">{{ currentLocale.name }}</span>
      </template>
      <b-dropdown-item
        v-for="localeObj in locales"
        :key="localeObj.locale"
        @click="changeLang(localeObj)"
      >
        <b-img
          :src="localeObj.img"
          height="14px"
          width="22px"
          :alt="localeObj.locale"
        />
        <span class="ml-50">{{ localeObj.name }}</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";
import { computed, inject } from "@vue/composition-api";
import store from "@/store";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
  },
  methods: {
    changeLang(localeObj) {
      if (this.$i18n.locale !== localeObj.locale) {
        this.$i18n.locale = localeObj.locale;
        localStorage.setItem("lang", localeObj.locale);
        localStorage.setItem("locale", localeObj.locale);
        this.$router.go(0);
      }
    },
  },

  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: "en",
        img: require("@/assets/images/flags/en.png"),
        name: "English",
      },
      {
        locale: "ar",
        img: require("@/assets/images/flags/ar.png"),
        name: "Arabic",
      },
    ];
    /* eslint-disable global-require */

    return {
      locales,
    };
  },
};
</script>

<style></style>
