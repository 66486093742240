export default [
  {
    title: "g.Explore",
    route: "Explore",
    icon: "explore.svg",
    iconActive: "explore-fill.svg",
  },
  {
    title: "g.Profile",
    route: "Profile",
    icon: "user.svg",
    iconActive: "user-fill.svg",
  },
];
